import React from "react"
import { Layout, FlexRow, NewsletterSection } from "@components/layout"
import { HomeAttentionSection, HomeSection } from "@components/standard"

const IndexPage = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <HomeAttentionSection />
      <HomeSection />
      <NewsletterSection />
    </Layout>
  )
}

export default IndexPage

// const {
// 	productsWithDetails,
// 	productCategories,
// 	productTags,
// 	page,
// } = pageContext
// const [products, setProducts] = useState(productsWithDetails)

// const onFilterProducts = (selectedTags) => {
// 	const selectedProducts = []

// 	for (const product of products) {
// 		for (const tag of selectedTags) {
// 			const tags =
// 				product.productTags.nodes &&
// 				product.productTags.nodes.map((el) => el.slug).join(' ')
// 			const cond = [
// 				product.name.includes(tag),
// 				product.slug.includes(tag),
// 				product.description.includes(tag),
// 				product.shortDescription.includes(tag),
// 				tags.includes(tag),
// 			]
// 			if (cond.includes(true)) {
// 				if (!selectedProducts.includes(product)) {
// 					selectedProducts.push(product)
// 				}
// 				break
// 			}
// 		}
// 	}
// 	if (selectedProducts.length !== 0) {
// 		setProducts(selectedProducts)
// 	}
// }
